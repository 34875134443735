import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import Cache from '../utils/cache'
import Layout from '@/layout/index.vue'
import routerView from '@/components/common/routerView.vue';
import { addRoutesByRole } from '@/utils/dynamicRoutes';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/login.html',
		name: 'login',
		component: () => import('../views/passport/login.vue'),
		meta: {
			title: '登录',
		}
	},
	{
		path: '/',
		name: 'index',
		component: Layout,
		redirect: '/',
		children: [{
			path: '/home',
			name: 'home',
			children: [
				{
					path: '/',
					name: 'home',
					component: () => import('../views/home/dashboard.vue'),
				}
			]
		},],
	},
	{
		path: '/examine',
		component: Layout,
		children: [
            {
                path: '/examine/advertList',
                name: 'advertList',
                component: () => import('@/views/examineAdmin/advertList.vue'),
                meta: {
                    title: '广告内容审核',
                }
            },
			{
                path: '/examine/advertPutinList',
                name: 'advertPutinList',
                component: () => import('@/views/examineAdmin/advertPutinList.vue'),
                meta: {
                    title: '广告投放审核',
                }
            },
			{
                path: '/examine/spaceList',
                name: 'spaceList',
                component: () => import('@/views/examineAdmin/spaceList.vue'),
                meta: {
                    title: '广告位置审核',
                }
            },
			{
                path: '/examine/paymentList',
                name: 'paymentList',
                component: () => import('@/views/examineAdmin/paymentList.vue'),
                meta: {
                    title: '支付汇款审核',
                }
            },
        ]
	},
	{
		path: '/apply',
		component: Layout,
		children: [
            {
                path: '/apply/applyList',
                name: 'applyList',
                component: () => import('@/views/applyAdmin/applyList.vue'),
                meta: {
                    title: '入驻审核',
                }
            }
        ]
	},
	{
		path: '/business',
		component: Layout,
		children: [
            {
                path: '/business/advert',
                name: 'advert',
                component: () => import('@/views/businessAdmin/advertAdmin.vue'),
                meta: {
                    title: '广告商管理',
                }
            },
			{
                path: '/business/restaurant',
                name: 'restaurant',
                component: () => import('@/views/businessAdmin/restaurantAdmin.vue'),
                meta: {
                    title: '餐厅管理',
                }
            }
        ]
	},
	{
		path: '/advert',
		component: Layout,
		children: [
            {
                path: '/advert/advertDeliver',
                name: 'advertDeliver',
                component: () => import('@/views/advertAdmin/advertDeliver.vue'),
                meta: {
                    title: '投放管理',
                }
            },
        ]
	},
	{
		path: '/advertSpace',
		component: Layout,
		children: [
            {
                path: '/advert/advertSpace',
                name: 'advertSpace',
                component: () => import('@/views/advertSpaceAdmin/spaceList.vue'),
                meta: {
                    title: '广告位管理',
                }
            },
        ]
	},
	{
		path: '/member',
		component: Layout,
		children: [
            {
                path: '/member/memberAdmin',
                name: 'memberAdmin',
                component: () => import('@/views/memberAdmin/memberAdmin.vue'),
                meta: {
                    title: '用户列表',
                }
            },
            {
                path: '/member/commissionDraw',
                name: 'commissionDraw',
                component: () => import('@/views/memberAdmin/commissionDraw.vue'),
                meta: {
                    title: '用户提现',
                }
            },
        ]
	},
    {
		path: '/collection',
		component: Layout,
		children: [
            {
                path: '/collection/advertCollection',
                name: 'advertCollection',
                component: () => import('@/views/advertCollection/advertCollection.vue'),
                meta: {
                    title: '收集列表',
                }
            },
        ]
	},
]

const router = createRouter({
	// process.env.BASE_UR
	history: createWebHistory("/"),
	routes
})
const whiteList = ['/login.html']; //白名单

router.beforeEach(async (to, from, next) => {
	document.title = to.meta.title ? String(to.meta.title) : '本家广告管理后台';
	let accessToken = store.getters.token || Cache.getToken(Cache.advertToken)
	const toPath: string = to.path;
	if (accessToken) {
		let user = store.getters.advertInfo || Cache.getStorage(Cache.advertUser);
		if (toPath === '/login.html') {
            return next({ path: '/' });
        }
		return next();
	}else{
		// 没有token
		if (whiteList.indexOf(to.path) !== -1) {
			// 在免登录白名单，直接进入
			next()
		} else {
			next(`/login.html`)
		}
	}
})
export default router
