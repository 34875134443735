import https from '../../utils/http'
import Layout from '@/layout/index.vue'
import router from "@/router";
import {ElMessage} from "element-plus";

const state: any = {
  adminMenuList: [],//路由列表
  adminRouteMap: [],
  menuLevel: {},//当前路由
  adminMenuShape: false,
  adminUserList: [],
}
const mutations = {
  ADD_MENU: (state: any, device: String) => {
    state.adminMenuList = device
  },
  ADD_SHOW: (state: any, device: Boolean) => {
    state.adminMenuShape = device
  },
  DEL_MENU: (state: any) => {
    state.adminMenuList = []
    state.adminRouteMap = []
    sessionStorage.clear()
  },
  TOGGLE_DEVICE: (state: any, device: String) => {
    state.menuLevel = device
  },
  DEL_TOGGLE_DEVICE: (state: any) => {
    state.menuLevel = {}
  },
  SET_ROUTE_MAP(state:any, routers:any) {
    state.adminRouteMap = routers
    // 为了防止用户刷新页面导致动态创建的路由失效，将其存储在本地中
    sessionStorage.setItem('adminRouteMap', JSON.stringify(routers));
  },
  SET_USER_LIST: (state, adminUserList) => {
    state.adminUserList = adminUserList
  },
  DEL_USER_LIST: (state: any) => {
    state.adminUserList = []
  }
}
const actions = {
  levelDevice(context: any, device: String) {
    context.commit('TOGGLE_DEVICE', device)
  },
  getMenuList(context: any, device: String) {
    context.commit('ADD_MENU', device)
    context.commit('SET_ROUTE_MAP', device)
  },
  menuDel(context: any) {
    context.commit('DEL_MENU')
    context.commit('DEL_TOGGLE_DEVICE')
  },
  // 获取用户门店列表
  getUserMerchantList(context: any, moduleType: any) {
    return new Promise((resolve, reject) => {
      https.get("/passport/get-user-merchant-list", moduleType, false,null).then((res: any) => {
        context.commit('SET_USER_LIST', res)
        resolve(res)
      }).catch((err: any) => {
        console.error(err)
      });
    })
  },
  showAdd(context: any, device: Boolean) {
    context.commit('ADD_SHOW', device)
  },
  clearUserList(context: any) {
    context.commit('DEL_USER_LIST')
  },
}
export const loadView = (view) => { // 路由懒加载
  if (!view) {
    return;
  }
  return () => import(`../../views/${view}.vue`)
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
