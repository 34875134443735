import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5b7ab251"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "view-box" }
const _hoisted_2 = { class: "content-box" }
const _hoisted_3 = { class: "main-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_left_nav = _resolveComponent("left-nav")!
  const _component_top_nav = _resolveComponent("top-nav")!
  const _component_tags_view = _resolveComponent("tags-view")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_left_nav, { class: "let-nav" })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_top_nav, { class: "top-nav" }),
      _createVNode(_component_tags_view),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view, { class: "main-content" })
      ])
    ])
  ]))
}