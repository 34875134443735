// 当前前台是否为开发模式
const isDev = process.env.NODE_ENV === 'development'
let deviertisingPortUrl = isDev ? 'http://192.168.0.154:8089' : 'https://mtest.bjftyx.net/advertisement-admin-api'
let deviertisingApiUrl = isDev ? 'http://192.168.0.154:8089' : 'https://mtest.bjftyx.net/advertisement-admin-api'
switch (process.env.VUE_APP_APP_HOST) {
	case 'development': //测试
        deviertisingPortUrl = 'https://mtest.bjftyx.net/advertisement-admin-api'
        deviertisingApiUrl = 'https://mtest.bjftyx.net/advertisement-admin-api'
		break
	case 'production': //正式
        deviertisingPortUrl = 'https://m.bjftyx.net/advertisement-admin-api'
        deviertisingApiUrl = 'https://m.bjftyx.net/advertisement-admin-api'
		break
}
export default {
	pageSize: 10,
	deviertisingPortUrl: deviertisingPortUrl,//参数请求接口
	deviertisingApiUrl: deviertisingApiUrl, //文件上传
	isDev: true,
}